<template>
  <div>
    <div class="root_cont">
      <div class="container">
        <div class="text-center">
          <div>
            <div class="mb-4">
              <svg viewBox="0 0 329.77 340.11" xml:space="preserve"
                   fill="currentColor"
                   width="80"
              >
                <g>
                <g transform="translate(135, 80)" fill="#5CBD55FF">
                  <circle transform="translate(25, 22)" r="44" fill="none" stroke="#5CBD55FF" stroke-width="5.5" />
                <path transform="scale(6)" d="M3.1,7.6L0.2,4.8C0,4.6-0.1,4.3,0.1,4.1c0.2-0.2,0.5-0.2,0.7,0l2.1,2l4.6-5.9C7.7,0,8-0.1,8.2,0.1
          c0.2,0.2,0.3,0.5,0.1,0.7L3.1,7.6z"/>
              </g>
                  <g>
                    <path
                        d="M328.946,338.191c0.04-0.056,0.077-0.113,0.114-0.171c0.042-0.066,0.083-0.132,0.121-0.199
			c0.035-0.061,0.067-0.123,0.099-0.186c0.034-0.066,0.067-0.133,0.097-0.201c0.03-0.068,0.058-0.137,0.085-0.207
			c0.025-0.065,0.05-0.131,0.072-0.197c0.025-0.074,0.047-0.148,0.068-0.224c0.018-0.066,0.036-0.132,0.051-0.199
			c0.017-0.075,0.032-0.151,0.045-0.227c0.013-0.071,0.024-0.142,0.033-0.214c0.009-0.072,0.016-0.144,0.022-0.217
			c0.006-0.08,0.01-0.159,0.012-0.239c0.001-0.038,0.006-0.076,0.006-0.115V109.054c0-2.292-1.709-4.18-3.922-4.47
			c-0.247-0.724-0.677-1.396-1.294-1.936l-48.891-42.8V4.513c0-2.493-2.021-4.513-4.513-4.513H61.062
			c-2.493,0-4.513,2.021-4.513,4.513v54.235L2.279,105.133c-0.447,0.254-0.86,0.585-1.212,1.001
			c-0.734,0.866-1.08,1.927-1.066,2.979v0.004c-0.001,0.063-0.002,0.125,0,0.188v226.098c-0.002,0.047,0,0.095,0,0.142v0.052
			c0,0.004,0.001,0.008,0.001,0.011c0.001,0.142,0.008,0.284,0.021,0.425c0.002,0.018,0.004,0.036,0.006,0.053
			c0.014,0.127,0.033,0.253,0.058,0.378c0.005,0.027,0.011,0.054,0.016,0.08c0.025,0.115,0.054,0.229,0.088,0.341
			c0.009,0.031,0.019,0.063,0.029,0.094c0.035,0.107,0.074,0.213,0.116,0.318c0.013,0.032,0.026,0.064,0.04,0.096
			c0.044,0.101,0.092,0.2,0.143,0.297c0.017,0.033,0.034,0.066,0.052,0.098c0.051,0.092,0.107,0.182,0.165,0.272
			c0.023,0.036,0.047,0.072,0.072,0.107c0.056,0.08,0.114,0.158,0.176,0.235c0.034,0.043,0.069,0.085,0.104,0.126
			c0.056,0.065,0.114,0.129,0.174,0.191c0.049,0.051,0.099,0.1,0.15,0.148c0.032,0.03,0.06,0.063,0.093,0.092
			c0.024,0.022,0.051,0.04,0.076,0.061c0.052,0.044,0.105,0.087,0.159,0.129c0.058,0.045,0.116,0.09,0.176,0.132
			c0.058,0.041,0.118,0.081,0.178,0.119c0.058,0.037,0.117,0.073,0.177,0.107c0.065,0.038,0.132,0.073,0.199,0.108
			c0.056,0.029,0.113,0.056,0.17,0.082c0.074,0.034,0.149,0.066,0.225,0.096c0.051,0.02,0.102,0.038,0.154,0.056
			c0.084,0.03,0.169,0.058,0.255,0.082c0.043,0.012,0.087,0.022,0.13,0.033c0.095,0.024,0.19,0.047,0.287,0.065
			c0.03,0.005,0.06,0.009,0.089,0.014c0.24,0.039,0.484,0.065,0.735,0.065h320.742c0,0,0.001,0,0.001,0s0,0,0.001,0
			c0.144,0,0.289-0.008,0.432-0.022c0.052-0.005,0.102-0.014,0.154-0.021c0.09-0.012,0.18-0.024,0.27-0.041
			c0.061-0.012,0.12-0.027,0.18-0.041c0.079-0.018,0.157-0.038,0.234-0.06c0.062-0.018,0.122-0.039,0.183-0.059
			c0.074-0.025,0.148-0.051,0.221-0.081c0.06-0.024,0.119-0.05,0.177-0.076c0.071-0.032,0.141-0.065,0.21-0.101
			c0.058-0.03,0.115-0.061,0.172-0.093c0.066-0.038,0.131-0.078,0.196-0.119c0.057-0.037,0.114-0.074,0.169-0.113
			c0.059-0.042,0.118-0.086,0.176-0.132c0.057-0.044,0.112-0.089,0.167-0.136c0.024-0.021,0.05-0.039,0.073-0.06
			c0.028-0.026,0.053-0.054,0.08-0.08c0.059-0.055,0.116-0.112,0.172-0.17c0.05-0.052,0.098-0.106,0.145-0.16
			c0.048-0.055,0.094-0.111,0.138-0.168C328.855,338.315,328.902,338.254,328.946,338.191z M9.028,118.798l104.135,88.262
			L9.028,323.751V118.798z M120.051,212.898l41.561,35.226c0.842,0.714,1.88,1.07,2.918,1.07c1.035,0,2.07-0.355,2.911-1.064
			l41.66-35.15l106.037,118.102H14.584L120.051,212.898z M216.004,207.157l104.739-88.373v205.029L216.004,207.157z
			 M318.221,109.101l-42.558,35.908V71.845L318.221,109.101z M65.576,9.027h201.06v143.599l-59.327,50.057
			c-0.299,0.164-0.586,0.361-0.851,0.598c-0.192,0.173-0.362,0.361-0.517,0.556l-41.403,34.934l-99.046-83.949
			c0.054-0.28,0.085-0.568,0.085-0.864V9.027z M56.549,147.242l-45.009-38.149l45.009-38.47V147.242z"
                    />
                  </g>
                </g>
              </svg>
            </div>

            <div>
              <p>
                <span class="h3 fw-400" v-html="t.p_1"></span>
              </p>
              <p>
                <span v-html="t.p_2"></span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const t =
    //<i18n>
    {
      en: {
        p_1: "Congratulations,<br> your subscription has been confirmed!",
        p_2: "Thank you for subscribing to our list.",
      },
      hr: {
        p_1: "Čestitamo,<br> Vaša prijava na e-mail obavijesti je potvrđena!",
        p_2: "Hvala Vam na prijavi.",
      }
    };
//</i18n>

import Modal from "../../components/Elements/Modal";

export default {
  name: "McSubscribeSuccessView",
  components: {Modal},
  data() {
    return {
      t: t[this.$lang],
    };
  },
  methods: {}
};
</script>

<style scoped>
.root_cont {
  padding: 160px 0;
  background-color: #fff;
  color: rgb(4, 43, 93);
  min-height: calc(100vh - 100px);
}

.h3 {
  display: block;
  margin: 0 auto;
  max-width: 320px;
}

.container {
  max-width: 600px !important;
}

.btn {
  border: solid rgb(4, 43, 93) 1px;
  color: rgb(4, 43, 93);
}

@media (max-width: 576px) {
  .root_cont {
    padding: 40px 0;
  }
}
</style>